import "./App.css";
import Navbar from "./components/Navbar";

function App() {
  return (
    <div className="App">
      <Navbar />
      <div
        data-w-id="fca27bf7-c8ab-bba8-f76a-93bf227c373c"
        style={{ opacity: "1" }}
        className="hero-wrapper"
      >
        <div className="image-wrapper">
          <img
            src="https://assets.website-files.com/62cdbbc7447f70278355dd0f/62cdca9f447f70e5d056c413_sft.png"
            loading="lazy"
            height=""
            width="275"
            sizes="192px"
            srcset="https://assets.website-files.com/62cdbbc7447f70278355dd0f/62cdca9f447f70e5d056c413_sft-p-500.png 500w, https://assets.website-files.com/62cdbbc7447f70278355dd0f/62cdca9f447f70e5d056c413_sft.png 636w"
            alt="Sharon F. Terry"
          />
        </div>
        <div className="quote-wrapper">
          <div className="quote">
            “I don’t see fear as a warning to turn away. I see it as an
            invitation to go forward because in it lies love and the path to
            greater love. If I turn with gentle curiosity toward that flicker, I
            discover new paths to everything from practical systems change to
            the core of healing and health.”
          </div>
          <div className="entry-date right">
            — Sharon F. Terry, ted Talk, 2015
          </div>
        </div>
      </div>
      <div class="grid">
        <div
          id="w-node-_25beaf8c-0195-3a33-75a2-3b32d8744a7b-ec55dd10"
          data-w-id="25beaf8c-0195-3a33-75a2-3b32d8744a7b"
          style={{ opacity: "1" }}
          class="card-wrapper"
        >
          <h1 class="heading">Blogs</h1>
          <a
            href="https://medium.com/@sterry_28417/i-want-to-show-up-fully-before-i-die-750477b45446"
            target="_blank"
            rel="noreferrer"
            class="entry-wrapper w-inline-block"
          >
            <div class="hr-40px"></div>
            <div class="entry-title">I want to show up fully before I die…</div>
            <div class="entry-date">2020</div>
          </a>
          <a
            href="https://medium.com/@sterry_28417/join-me-in-my-non-retirement-b57cef0cea0f"
            target="_blank"
            rel="noreferrer"
            class="entry-wrapper w-inline-block"
          >
            <div class="hr-40px"></div>
            <div class="entry-title">Join me in my “nonretirement”</div>
            <div class="entry-date">2021</div>
          </a>
        </div>
        <div
          id="w-node-_48c0441c-d31f-99f6-8672-5a434d526972-ec55dd10"
          data-w-id="48c0441c-d31f-99f6-8672-5a434d526972"
          style={{ opacity: "1" }}
          class="card-wrapper"
        >
          <h1 class="heading">Talks</h1>
          <a
            href="https://www.ted.com/talks/sharon_terry_science_didn_t_understand_my_kids_rare_disease_until_i_decided_to_study_it"
            target="_blank"
            rel="noreferrer"
            class="entry-wrapper w-inline-block"
          >
            <div class="hr-40px"></div>
            <div class="entry-title">
              TED Talk — Science didn’t understand my kids’ disease until I
              decided to study it
            </div>
            <div class="entry-date">2016</div>
          </a>
          <a
            href="https://www.youtube.com/watch?v=hGuC0-On7hQ"
            target="_blank"
            rel="noreferrer"
            class="entry-wrapper w-inline-block"
          >
            <div class="hr-40px"></div>
            <div class="entry-title">Big Data: don’t forget the people</div>
            <div class="entry-date">2015</div>
          </a>
          <a
            href="https://www.youtube.com/watch?v=0t9F7x3rFiU"
            target="_blank"
            rel="noreferrer"
            class="entry-wrapper w-inline-block"
          >
            <div class="hr-40px"></div>
            <div class="entry-title">For my kids and all kids</div>
            <div class="entry-date">2011</div>
          </a>
          <a
            href="https://www.nih.gov/allofus-research-program/faces-precision-medicine-initiative-sharon-terry"
            target="_blank"
            rel="noreferrer"
            class="entry-wrapper w-inline-block"
          >
            <div class="hr-40px"></div>
            <div class="entry-title">
              The Precision Medicine Initiative (All of US)
            </div>
            <div class="entry-date">2015</div>
          </a>
          <a
            href="https://www.youtube.com/watch?v=OokV_Q5he34"
            target="_blank"
            rel="noreferrer"
            class="entry-wrapper w-inline-block"
          >
            <div class="hr-40px"></div>
            <div class="entry-title">
              National Library of Medicine Anniversary
            </div>
            <div class="entry-date">2010</div>
          </a>
        </div>
        <div
          id="w-node-f1ad64bb-778c-ee94-d760-7e85e64e702b-ec55dd10"
          data-w-id="f1ad64bb-778c-ee94-d760-7e85e64e702b"
          style={{ opacity: "1" }}
          class="card-wrapper"
        >
          <h1 class="heading">Interviews</h1>
          <a
            href="https://www.youtube.com/watch?v=zYsJKiIyvHU"
            target="_blank"
            rel="noreferrer"
            class="entry-wrapper w-inline-block"
          >
            <div class="hr-40px"></div>
            <div class="entry-title">PBS NewsHour</div>
            <div class="entry-date">2022</div>
          </a>
          <a
            href="https://www.npr.org/2017/09/29/554102828/sharon-terry-when-siblings-get-a-rare-diagnosis-can-their-parents-find-the-cure"
            target="_blank"
            rel="noreferrer"
            class="entry-wrapper w-inline-block"
          >
            <div class="hr-40px"></div>
            <div class="entry-title">TED Radio Hour</div>
            <div class="entry-date">2017</div>
          </a>
          <a
            href="https://www.forbes.com/sites/ashoka/2020/05/08/from-competition-to-sharing-how-her-childrens-rare-disease-led-sharon-terry-to-revolutionize-medical-research"
            target="_blank"
            rel="noreferrer"
            class="entry-wrapper w-inline-block"
          >
            <div class="hr-40px"></div>
            <div class="entry-title">
              From Competition To Sharing: How Her Children’s Rare Disease Led
              Sharon Terry To Revolutionize Medical Research • Forbes
            </div>
            <div class="entry-date">2020</div>
          </a>
          <a
            href="https://www.youtube.com/watch?v=jV15wfLVkiM&amp;t=1s"
            target="_blank"
            rel="noreferrer"
            class="entry-wrapper w-inline-block"
          >
            <div class="hr-40px"></div>
            <div class="entry-title">Unfinished Live</div>
            <div class="entry-date">2020</div>
          </a>
        </div>
        <div
          id="w-node-_2820abbc-abf1-20cb-cc87-4c2c2a3813b5-ec55dd10"
          data-w-id="2820abbc-abf1-20cb-cc87-4c2c2a3813b5"
          style={{ opacity: "1" }}
          class="card-wrapper"
        >
          <h1 class="heading">Podcasts</h1>
          <a
            href="https://glorikian.com/sharon-terry-on-changes-in-drug-discovery-diagnostics-and-treatment-of-patients/"
            target="_blank"
            rel="noreferrer"
            class="entry-wrapper w-inline-block"
          >
            <div class="hr-40px"></div>
            <div class="entry-title">
              Sharon Terry On Changes In Drug Discovery, Diagnostics, And The
              Treatment Of Patients — Harry Glorikian
            </div>
            <div class="entry-date">2020</div>
          </a>
        </div>
        <div
          id="w-node-_7036696f-61bd-cc66-2594-ac02e9759581-ec55dd10"
          data-w-id="7036696f-61bd-cc66-2594-ac02e9759581"
          style={{ opacity: "1" }}
          class="card-wrapper"
        >
          <h1 class="heading">Press Releases</h1>
          <a
            href="https://www.ashg.org/publications-news/press-releases/202107-advocacy-award/"
            target="_blank"
            rel="noreferrer"
            class="entry-wrapper w-inline-block"
          >
            <div class="hr-40px"></div>
            <div class="entry-title">
              ASHG Honors Sharon Terry, MA with the 2021 ASHG Advocacy Award
            </div>
            <div class="entry-date">2020</div>
          </a>
          <a
            href="https://www.prnewswire.com/news-releases/illumina-and-genetic-alliance-launch-120-million-global-initiative-to-increase-equity-and-improve-outcomes-for-families-impacted-by-genetic-disease-301424797.html"
            target="_blank"
            rel="noreferrer"
            class="entry-wrapper w-inline-block"
          >
            <div class="hr-40px"></div>
            <div class="entry-title">
              iHope Genetic Health: Illumina’s $120M in-kind donation
            </div>
            <div class="entry-date">2021</div>
          </a>
          <a
            href="https://www.einnews.com/pr_news/566570497/genetic-alliance-selected-for-amazon-web-services-aws-health-equity-program"
            target="_blank"
            rel="noreferrer"
            class="entry-wrapper w-inline-block"
          >
            <div class="hr-40px"></div>
            <div class="entry-title">
              Genetic Alliance Selected for AWS Health Equity Initiative
            </div>
            <div class="entry-date">2021</div>
          </a>
        </div>
        <div
          id="w-node-_4bf17cf2-b0f9-816c-c06f-163e8d3dd06b-ec55dd10"
          data-w-id="4bf17cf2-b0f9-816c-c06f-163e8d3dd06b"
          style={{ opacity: "1" }}
          class="card-wrapper"
        >
          <h1 class="heading">Papers</h1>
          <a
            href="https://drive.google.com/file/d/1g-sSCCuZ8FpKrY7TPZtWMhKA-jqjbH2G/view?usp=sharing"
            target="_blank"
            rel="noreferrer"
            class="entry-wrapper w-inline-block"
          >
            <div class="hr-40px"></div>
            <div class="entry-title">Life as a Numerator</div>
            <div class="entry-date">2016</div>
          </a>
          <a
            href="https://drive.google.com/file/d/1G8KuzhdgykCFHb3qfhlDbZmqZEN-QsQo/view?usp=sharing"
            target="_blank"
            rel="noreferrer"
            class="entry-wrapper w-inline-block"
          >
            <div class="hr-40px"></div>
            <div class="entry-title">Learning Genetics</div>
            <div class="entry-date">2003</div>
          </a>
          <a
            href="https://drive.google.com/file/d/1oIsByvtlCzDsR2qtN5sXuP_qw1wO3DvF/view?usp=sharing"
            target="_blank"
            rel="noreferrer"
            class="entry-wrapper w-inline-block"
          >
            <div class="hr-40px"></div>
            <div class="entry-title">
              The Study is Open: Participants are Recruiting Investigators
            </div>
            <div class="entry-date">2017</div>
          </a>
        </div>
        <div
          id="w-node-_47f25a8b-a5cb-8708-0c36-26865389a47c-ec55dd10"
          data-w-id="47f25a8b-a5cb-8708-0c36-26865389a47c"
          style={{ opacity: "1" }}
          class="card-wrapper"
        >
          <h1 class="heading">Awards &amp; Honors</h1>
          <div class="award-list-wrapper">
            <div class="award-entry">
              <div class="hr-40px"></div>
              <div class="award-text-wrapper">
                <div class="entry-date award">2005</div>
                <div class="entry-title award">
                  Honorary Doctorate for community engagement, Iona College
                </div>
              </div>
            </div>
            <div class="award-entry">
              <div class="hr-40px"></div>
              <div class="award-text-wrapper">
                <div class="entry-date award">2007</div>
                <div class="entry-title award">
                  1st Annual Patient Service Award, UNC Institute for
                  Pharmacogenomics and Individualized Therapy
                </div>
              </div>
            </div>
            <div class="award-entry">
              <div class="hr-40px"></div>
              <div class="award-text-wrapper">
                <div class="entry-date award">2009</div>
                <div class="entry-title award">
                  Inaugural Disruptive Woman in Health Care
                </div>
              </div>
            </div>
            <div class="award-entry">
              <div class="hr-40px"></div>
              <div class="award-text-wrapper">
                <div class="entry-date award">2009</div>
                <div class="entry-title award">Elected Ashoka Fellow</div>
              </div>
            </div>
            <div class="award-entry">
              <div class="hr-40px"></div>
              <div class="award-text-wrapper">
                <div class="entry-date award">2010</div>
                <div class="entry-title award">
                  Research!America’s 2011 Paul G. Rogers Distinguished
                  Organization Advocacy Award
                </div>
              </div>
            </div>
            <div class="award-entry">
              <div class="hr-40px"></div>
              <div class="award-text-wrapper">
                <div class="entry-date award">2011</div>
                <div class="entry-title award">
                  Clinical Research Forum’s Public Advocacy Award
                </div>
              </div>
            </div>
            <div class="award-entry">
              <div class="hr-40px"></div>
              <div class="award-text-wrapper">
                <div class="entry-date award">2012</div>
                <div class="entry-title award">
                  Spirit of Empowerment Advocacy Award, Facing Our Risk of
                  Cancer Empowered
                </div>
              </div>
            </div>
            <div class="award-entry">
              <div class="hr-40px"></div>
              <div class="award-text-wrapper">
                <div class="entry-date award">2012</div>
                <div class="entry-title award">
                  Honorary Professorship, Hebei United University, Tangshan,
                  China
                </div>
              </div>
            </div>
            <div class="award-entry">
              <div class="hr-40px"></div>
              <div class="award-text-wrapper">
                <div class="entry-date award">2012</div>
                <div class="entry-title award">
                  Collaborate|Activate Innovation Challenge First Prize Award
                  for Platform for Engaging Everyone Responsibly (PEER)
                </div>
              </div>
            </div>
            <div class="award-entry">
              <div class="hr-40px"></div>
              <div class="award-text-wrapper">
                <div class="entry-date award">2013</div>
                <div class="entry-title award">
                  Ashoka Changemakers First Prize for PEER
                </div>
              </div>
            </div>
            <div class="award-entry">
              <div class="hr-40px"></div>
              <div class="award-text-wrapper">
                <div class="entry-date award">2013</div>
                <div class="entry-title award">
                  Forbes Best Business Model for Transforming Health Systems for
                  PEER
                </div>
              </div>
            </div>
            <div class="award-entry">
              <div class="hr-40px"></div>
              <div class="award-text-wrapper">
                <div class="entry-date award">2013</div>
                <div class="entry-title award">
                  FDA Hero for the 30th Anniversary of the Orphan Drug Act
                </div>
              </div>
            </div>
            <div class="award-entry">
              <div class="hr-40px"></div>
              <div class="award-text-wrapper">
                <div class="entry-date award">2014</div>
                <div class="entry-title award">
                  Women of Impact, Robert Wood Johnson Foundation
                </div>
              </div>
            </div>
            <div class="award-entry">
              <div class="hr-40px"></div>
              <div class="award-text-wrapper">
                <div class="entry-date award">2015</div>
                <div class="entry-title award">
                  Robert Wood Johnson Foundation Pioneer Award
                </div>
              </div>
            </div>
            <div class="award-entry">
              <div class="hr-40px"></div>
              <div class="award-text-wrapper">
                <div class="entry-date award">2016</div>
                <div class="entry-title award">
                  National Associate, National Research Council
                </div>
              </div>
            </div>
            <div class="award-entry">
              <div class="hr-40px"></div>
              <div class="award-text-wrapper">
                <div class="entry-date award">2016</div>
                <div class="entry-title award">Health 2.0 Activist Award</div>
              </div>
            </div>
            <div class="award-entry">
              <div class="hr-40px"></div>
              <div class="award-text-wrapper">
                <div class="entry-date award">2016</div>
                <div class="entry-title award">
                  TedMed Talk (&gt;1M views) and TED Radio Hour
                </div>
              </div>
            </div>
            <div class="award-entry">
              <div class="hr-40px"></div>
              <div class="award-text-wrapper">
                <div class="entry-date award">2019</div>
                <div class="entry-title award">
                  Luminary Award from the Precision Medicine World Conference
                </div>
              </div>
            </div>
            <div class="award-entry">
              <div class="hr-40px"></div>
              <div class="award-text-wrapper">
                <div class="entry-date award">2021</div>
                <div class="entry-title award">
                  American Society of Human Genetics Advocacy Award
                </div>
              </div>
            </div>
            <div class="award-entry"></div>
            <a
              id="w-node-_434e69a5-a657-429e-1c4c-572245bdf456-ec55dd10"
              href="https://drive.google.com/file/d/14dWWt5_ZO1x0B5tHUnThbxeTT1Rjc071/view?ts=62e97c33"
              target='_blank'
              rel='noreferrer'
              class="button w-button"
            >
              View my biosketch
            </a>
          </div>
        </div>
      </div>
      <Navbar />
    </div>
  );
}

export default App;
