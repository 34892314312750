import React from "react";

const Navbar = () => {
  return (
    <nav className="nav">
      <h2>Sharon F. Terry, MA</h2>
      <div className="social-wrapper">
        <a
          href="https://twitter.com/sharonfterry"
          target="_blank"
          rel="noreferrer"
          className="social-icon w-inline-block"
        >
          <div className="w-embed">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16 3C15.4 3.3 14.8 3.4 14.1 3.5C14.8 3.1 15.3 2.5 15.5 1.7C14.9 2.1 14.2 2.3 13.4 2.5C12.8 1.9 11.9 1.5 11 1.5C9.3 1.5 7.8 3 7.8 4.8C7.8 5.1 7.8 5.3 7.9 5.5C5.2 5.4 2.7 4.1 1.1 2.1C0.8 2.6 0.7 3.1 0.7 3.8C0.7 4.9 1.3 5.9 2.2 6.5C1.7 6.5 1.2 6.3 0.7 6.1C0.7 7.7 1.8 9 3.3 9.3C3 9.4 2.7 9.4 2.4 9.4C2.2 9.4 2 9.4 1.8 9.3C2.2 10.6 3.4 11.6 4.9 11.6C3.8 12.5 2.4 13 0.8 13C0.5 13 0.3 13 0 13C1.5 13.9 3.2 14.5 5 14.5C11 14.5 14.3 9.5 14.3 5.2C14.3 5.1 14.3 4.9 14.3 4.8C15 4.3 15.6 3.7 16 3Z"
                fill="#currentColor"
              ></path>
            </svg>
          </div>
        </a>
        <a
          href="https://www.linkedin.com/in/sharonterry"
          target="_blank"
          rel="noreferrer"
          className="social-icon w-inline-block"
        >
          <div className="w-embed">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_4_89)">
                <path
                  d="M15.3 0H0.7C0.3 0 0 0.3 0 0.7V15.4C0 15.7 0.3 16 0.7 16H15.4C15.8 16 16.1 15.7 16.1 15.3V0.7C16 0.3 15.7 0 15.3 0ZM4.7 13.6H2.4V6H4.8V13.6H4.7ZM3.6 5C2.8 5 2.2 4.3 2.2 3.6C2.2 2.8 2.8 2.2 3.6 2.2C4.4 2.2 5 2.8 5 3.6C4.9 4.3 4.3 5 3.6 5ZM13.6 13.6H11.2V9.9C11.2 9 11.2 7.9 10 7.9C8.8 7.9 8.6 8.9 8.6 9.9V13.7H6.2V6H8.5V7C8.8 6.4 9.6 5.8 10.7 5.8C13.1 5.8 13.5 7.4 13.5 9.4V13.6H13.6Z"
                  fill="#currentColor"
                ></path>
              </g>
              <defs>
                <clipPath id="clip0_4_89">
                  <rect width="16" height="16" fill="white"></rect>
                </clipPath>
              </defs>
            </svg>
          </div>
        </a>
        <a
          href="mailto:sterry@geneticalliance.org"
          target="_blank"
          rel="noreferrer"
          className="social-icon w-inline-block"
        >
          <div className="w-embed">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_4_97)">
                <path
                  d="M14 1H2C1.46957 1 0.960859 1.21071 0.585786 1.58579C0.210714 1.96086 0 2.46957 0 3L0 3.4L8 7.9L16 3.5V3C16 2.46957 15.7893 1.96086 15.4142 1.58579C15.0391 1.21071 14.5304 1 14 1Z"
                  fill="#currentColor"
                ></path>
                <path
                  d="M7.5 9.90001L0 5.70001V13C0 13.5304 0.210714 14.0392 0.585786 14.4142C0.960859 14.7893 1.46957 15 2 15H14C14.5304 15 15.0391 14.7893 15.4142 14.4142C15.7893 14.0392 16 13.5304 16 13V5.70001L8.5 9.90001C8.3424 9.96926 8.17214 10.005 8 10.005C7.82786 10.005 7.6576 9.96926 7.5 9.90001Z"
                  fill="#currentColor"
                ></path>
              </g>
              <defs>
                <clipPath id="clip0_4_97">
                  <rect width="16" height="16" fill="white"></rect>
                </clipPath>
              </defs>
            </svg>
          </div>
        </a>
      </div>
    </nav>
  );
};

export default Navbar;
